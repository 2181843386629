body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; 
  margin: 0px;
  padding: 0px;
  background-color: #343a40;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  display: flex;
  min-height: 100%; 
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

.center{
  align-items: center;
  text-align: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.logo {
  height: auto;
  max-width: 3rem;
  width: 2rem;
}

.myPicture {
  height: auto;
  width: 250px;
  max-width: 50%vh;
}

.horizontalMargin {
  margin-left: 1rem;
  margin-right: 1rem;
}

.displayInline {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}
